// import stores
import AuthStore from './AuthStore'
import AppStateStore from './AppStateStore'
import UnitreeStore from './unitree/UnitreeStore'
import TreeStore from './unitree/TreeStore'
import NodeStore from './unitree/NodeStore'
import DialogNodeStore from './unitree/DialogNodeStore'
import DialogNodeFilterStore from './unitree/DialogNodeFilterStore'
import ArticlesStore from './unitree/ArticlesStore'
import TileStore from './unitree/TileStore'
import ImporterStore from './ImporterStore'
import TilesExternalStore from './unitree/TilesExternalStore'
import ShopsStore from './ShopsStore'
import UsersStore from './UsersStore'
import AppStore from './portal/AppStore'
import OverviewStore from './portal/OverviewStore'
import ArticleStore from './portal/ArticleStore'
import ESObjectStore from './ESObjectStore'
import CategoriesStore from './categories/CategoriesStore'
import CategoriesTreeStore from './categories/CategoriesTreeStore'
import DialogCategoryStore from './categories/DialogCategoryStore'
import GenartsStore from './categories/GenartsStore'
import GenartFiltersStore from './categories/GenartFiltersStore'
import GenartCriteriaStore from './categories/GenartCriteriaStore'
import GenartCupisStore from './categories/GenartCupisStore'
import ConfigAmStore from './categories/ConfigAmStore'
import ConfigCupisStore from './categories/ConfigCupisStore'
import BrandPriorityStore from './brand_priority/BrandPriorityStore'
import BrandsStore from './brand_priority/BrandsStore'
import SortsStore from './brand_priority/SortsStore'
import FormatGaStore from './format_ga/FormatGaStore'
import ElementsStore from './format_ga/ElementsStore'
import FiltersStore from './format_ga/FiltersStore'
class Store {
  constructor (history) {
    const updated = this.checkAppVersion()
    if (updated) window.location.reload(true)
    this.appStateStore = new AppStateStore(history)
    if (localStorage.getItem('fe-vupdated') !== 'true') {
      this.appStateStore.setGlobalMsgBar(true, 'success', `Updated ${process.env.NODE_ENV} BP to ${process.env.REACT_APP_VERSION}`)
      localStorage.setItem('fe-vupdated', 'true')
    }
    this.authStore = new AuthStore(history, this.appStateStore)
    this.esObjectStore = new ESObjectStore(this.authStore)
    this.unitreeStore = new UnitreeStore(this.authStore, this.esObjectStore)
    this.nodeStore = new NodeStore(this.authStore, this.esObjectStore, this.unitreeStore)
    this.tileStore = new TileStore(this.authStore, this.unitreeStore, this.nodeStore)
    this.treeStore = new TreeStore(this.authStore, this.esObjectStore, this.unitreeStore, this.tileStore, this.nodeStore)
    this.dialogNodeStore = new DialogNodeStore(this.authStore, this.nodeStore)
    this.dialogNodeFilterStore = new DialogNodeFilterStore(this.authStore, this.dialogNodeStore)
    this.articlesStore = new ArticlesStore(this.authStore, this.esObjectStore, this.nodeStore)
    this.tilesExternalStore = new TilesExternalStore(this.authStore, this.tileStore)
    this.importerStore = new ImporterStore(this.authStore)
    this.shopsStore = new ShopsStore(this.authStore, this.unitreeStore)
    this.appStore = new AppStore(this.authStore)
    this.overviewStore = new OverviewStore(this.authStore)
    this.articleStore = new ArticleStore(this.authStore, this.appStore)
    this.categoriesStore = new CategoriesStore(this.authStore, this.esObjectStore)
    this.categoriesTreeStore = new CategoriesTreeStore(this.authStore, this.esObjectStore, this.categoriesStore)
    this.dialogCategoryStore = new DialogCategoryStore(this.authStore, this.esObjectStore, this.categoriesTreeStore)
    this.genartsStore = new GenartsStore(this.authStore, this.esObjectStore, this.categoriesTreeStore)
    this.genartFiltersStore = new GenartFiltersStore(this.authStore, this.genartsStore)
    this.genartCriteriaStore = new GenartCriteriaStore(this.authStore, this.categoriesStore, this.genartsStore)
    this.genartCupisStore = new GenartCupisStore(this.authStore, this.genartsStore)
    this.configAmStore = new ConfigAmStore(this.authStore, this.esObjectStore)
    this.configCupisStore = new ConfigCupisStore(this.authStore, this.esObjectStore)
    this.brandPriorityStore = new BrandPriorityStore(this.authStore, this.esObjectStore)
    this.brandsStore = new BrandsStore(this.authStore, this.esObjectStore, this.brandPriorityStore)
    this.sortsStore = new SortsStore(this.authStore, this.esObjectStore, this.brandPriorityStore)
    this.formatGaStore = new FormatGaStore(this.authStore, this.esObjectStore)
    this.elementsStore = new ElementsStore(this.authStore, this.esObjectStore, this.formatGaStore)
    this.filtersStore = new FiltersStore(this.authStore, this.esObjectStore, this.formatGaStore)
    this.usersStore = new UsersStore(this.authStore)
    this.authStore.initApp()
  }

  checkAppVersion = () => {
    const currentVersion = localStorage.getItem('fe-version')
    if (currentVersion !== process.env.REACT_APP_VERSION) {
      localStorage.clear()
      localStorage.setItem('fe-version', process.env.REACT_APP_VERSION)
      console.log(`Updated ${process.env.NODE_ENV} to ${process.env.REACT_APP_VERSION}`)
      return true
    }
    return false
  }
}

export default Store
