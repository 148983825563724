import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Selection
  } from '@fluentui/react/lib-commonjs/DetailsList'
import { Label, ScrollablePane, ScrollbarVisibility, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, TextField,
  Dialog, DialogType, DialogFooter, Checkbox } from '@fluentui/react'
import { TooltipHost } from '@fluentui/react/lib/Tooltip'
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky'
import withMainContainer from '../withMainContainer'
import { withTranslation } from 'react-i18next'
import { Dropdown } from 'semantic-ui-react'
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling'

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }

const theme = getTheme()
const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
})

@inject('store')
@observer
class Filters extends Component {
  constructor (props) {
    super(props)
    this.formatGaStore = this.props.store.formatGaStore
    this.filtersStore = this.props.store.filtersStore
    this.esObjectStore = this.props.store.esObjectStore
    this.selection = new Selection();

    this.default_ref = React.createRef()
    
    this.dragDropEvents = this.getDragDropEvents()
    this.draggedItem = undefined
    this.draggedIndex = -1
    this.state = {
      hideDelDialog: true
    }
  }

  setCriteriaHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.filtersStore.criteriaColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.filtersStore.constCriteriaHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  handleFilterItemColumn = (item, index, column) => {
    if (column.fieldName === 'bar') {
      return(<span
          style={{
            display: 'flex', 
            justifyContent: 'center'
          }}
        >
          {item[column.fieldName]}
        </span>)
    }
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={editIcon}
            title={t('common:FILTER_EDIT')}
            onClick={() => this.showCriteriaDialog(item, index)}
          />
          <IconButton
            onClick={() => this.showCriteriaDelDialog(index)}
            iconProps={delIcon}
            title={t('common:FILTER_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  showCriteriaDialog = (item, index) => {
    if (item === undefined) {
      const sortFilters = this.filtersStore.filters.map(filter => Number(filter.sort))
      item = {
       default: null,
       bar: "false",
      //  filter_open: 0,
      //  filter_uom: '',
       sort: (sortFilters.length > 0 ? Math.max(...sortFilters) + 1 : 1).toString(),
       isEdit: false
      }
    } else {
      item.isEdit = true
      // save values before editing
      this.setState({
        criteriaEditIndex: index,
        origFilters: JSON.stringify(item)
      })
    }
    this.filtersStore.handleCriteriaDialog(item)
  }

  handleCloseCriteriaDialog = () => {
    this.filtersStore.handleCriteriaDialog()
    const { criteriaEditIndex, origFilters } = this.state
    if (criteriaEditIndex !== undefined) {
        this.filtersStore.filters[criteriaEditIndex] = JSON.parse(origFilters) 
        this.setState({
            criteriaEditIndex: undefined,
            origFilters: ''
        })
    }
  }

  handleCriteriaChange = (key, value) => {
    if (this.filtersStore.criteriaItem !== undefined) {
        this.filtersStore.criteriaItem[key] = value
    }
  }

  handleCriteriaEdit = () => {
    if (!this.filtersStore.criteriaItem.caid) {
      alert(this.props.t('common:MSG_SELECT_CRITERIA'))
      return
    }
    // save in criteriaItem the values from refs inputs
    this.filtersStore.criteriaItem.default = this.default_ref.current.value

    this.filtersStore.editCriteria()
  }

  showCriteriaDelDialog = index => {
    this.setState({
      hideCriteriaDelDialog: false,
      criteriaDelIndex: index
    })
  }

  handleCloseCriteriaDelDialog = () => {
    this.setState({ hideCriteriaDelDialog: true })
  }

  handleDeleteCriteria = () => {
    this.filtersStore.removeCriteria(this.state.criteriaDelIndex)
    this.setState({
      hideCriteriaDelDialog: true,
      criteriaDelIndex: undefined
    })
  }

  getDragDropEvents = () => {
    return {
      canDrop: (dropContext, dragContext) => {
        return true
      },
      canDrag: (item) => {
        return true
      },
      onDragEnter: (item, event) => {
        // return string is the css classes that will be added to the entering element.
        return dragEnterClass
      },
      onDragLeave: (item, event) => {
        return
      },
      onDrop: (item, event) => {
        if (this.draggedItem) {
          this.insertBeforeItem(item);
        }
      },
      onDragStart: (item, itemIndex, selectedItems, event) => {
        this.draggedItem = item;
        this.draggedIndex = itemIndex;
      },
      onDragEnd: (item, event) => {
        this.draggedItem = undefined;
        this.draggedIndex = -1;
      },
    };
  }

  insertBeforeItem = async(item) => {
    const draggedItems = this.selection.isIndexSelected(this.draggedIndex)
      ? (this.selection.getSelection()) : [this.draggedItem]

    const insertIndex = this.filtersStore.filters.indexOf(item)
    const items = this.filtersStore.filters.filter(itm => draggedItems.indexOf(itm) === -1)

    items.splice(insertIndex, 0, ...draggedItems)
    this.filtersStore.filters = items

    // update sort field
    const sort = this.filtersStore.filters.map(item => Number(item.sort))
    const minsort = sort.length > 0 ? Math.min(...sort) : 1
    for (let index = 0; index < this.filtersStore.filters.length; index++) {
      this.filtersStore.filters[index].sort = (minsort+index).toString()
    }
    this.formatGaStore.editItem.filters = this.filtersStore.filters
  }

  renderFixedDetailsHeader = (props, defaultRender) => {
    if (!props) {
      return null;
    }
    const onRenderColumnHeaderTooltip = 
       tooltipHostProps => (
          <TooltipHost {...tooltipHostProps} />
        );
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender({
           ...props,
           styles: {
            root: {
              selectors: {
                '.ms-DetailsHeader-cellTitle': {
                  display: 'flex',
                  justifyContent: 'center'
                },
              },
            },
           },
           onRenderColumnHeaderTooltip,
        })}
      </Sticky>
    );
  }

  render () {
    const { t, store: { filtersStore: { filters, showCriteriaDialog, criteriaItem },
      esObjectStore : { defaultLang, criterias, mapCriterias } } }  = this.props
    const { hideCriteriaDelDialog, criteriaDelIndex } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    const containerStackTokens = {
      childrenGap: 10,
      padding: 10
    }
    const textFieldsStyles = {
      fieldGroup:  {width: 310, height: 20, float: 'right' }
    } 
    return (
      <React.Fragment>
       <Stack tokens={containerStackTokens} style={{height: 'inherit'}}>  
          <Stack verticall tokens={stackTokens} >
            <Stack horizontal tokens={stackTokens}>
              <IconButton
                iconProps={addIcon}
                title={t('common:FILTER_ADD')}
                onClick={() => this.showCriteriaDialog()}
              />
            </Stack>
            <div style={{ position: "relative", height: 300 }} id='filters'>    
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                <DetailsList id='filterDetailsList'
                  items={filters} 
                  setKey='set'
                  columns={this.setCriteriaHeaderColumns()}
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                  selection={this.selection}
                  enterModalSelectionOnTouch
                  constrainMode={ConstrainMode.unconstrained}
                  onRenderItemColumn={this.handleFilterItemColumn}
                  dragDropEvents={this.dragDropEvents}
                  onRenderDetailsHeader={this.renderFixedDetailsHeader}
                />
              </ScrollablePane>   
            </div>
          </Stack>
        </Stack>
        { showCriteriaDialog && <Dialog
            minWidth={400}
            maxWidth={600}
            hidden={!showCriteriaDialog}
            onDismiss={this.handleCloseCriteriaDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: criteriaItem !== undefined && criteriaItem.isEdit === true ? t('common:CRITERIA_EDIT') : t('common:CRITERIA_ADD'),
              closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            }}
            modalProps={{
              isBlocking: true, dragOptions: true, 
            }}
          >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} style={{height: '20rem'}}>
            <Stack horizontal tokens={stackTokens}>
              <Label>{t('common:NODE_FILTER_CAID')}</Label>
              <Dropdown
                placeholder={t('common:NODE_FILTER_CAID')}
                defaultValue={criteriaItem !== undefined ? criteriaItem.caid : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={criterias}
                onChange={(e, { value }) => {
                  this.handleCriteriaChange('caid', value)
                  this.handleCriteriaChange('catxt', mapCriterias.get(defaultLang).get(value))
                }}
              />
            </Stack>
            <TextField label={t('common:NODE_FILTER_DEFAULT')} ref={this.default_ref} id='default' defaultValue={criteriaItem !== undefined ? criteriaItem.default : null} styles={textFieldsStyles}/>  
            <Checkbox label={t('common:NODE_FILTER_BAR')} defaultChecked={criteriaItem !== undefined && criteriaItem.bar === "true" ? true : false} onChange={(event, newValue) => this.handleCriteriaChange('bar', newValue ? "true" : "false")} />
            {/* <Checkbox label={t('common:NODE_FILTER_OPEN')} defaultChecked={criteriaItem !== undefined && criteriaItem.open === 1 ? true : false} onChange={(event, newValue) => this.handleCriteriaChange('open', newValue ? 1 : 0)} /> */}
            {/* <TextField label={t('common:NODE_FILTER_UOM')} ref={this.uom_ref} id='filter_uom' defaultValue={criteriaItem !== undefined ? criteriaItem.uom : ''} styles={textFieldsStyles}/>  */}
          </Stack>
            <DialogFooter>
              <PrimaryButton onClick={this.handleCriteriaEdit} text={t('common:BUTTON_SAVE')} />
              <DefaultButton onClick={this.handleCloseCriteriaDialog} text={t('common:BUTTON_CANCEL')} />
            </DialogFooter>
        </Dialog>
        }
        <Dialog
          hidden={hideCriteriaDelDialog}
          onDismiss={this.handleCloseCriteriaDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:CRITERIA_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:CRITERIA_DELETE_QESTION') + (filters[criteriaDelIndex] !== undefined ? `${filters[criteriaDelIndex]?.caid} - ${filters[criteriaDelIndex]?.catxt}` : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDeleteCriteria} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseCriteriaDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
      </React.Fragment> 
    )
  }
}

export { Filters }
export default withTranslation(['common'], { wait: true })(withMainContainer(Filters))