import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { TextField, Checkbox, Label, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import withMainContainer from './Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }

@inject('store')
@observer
class Users extends Component {
  constructor (props) {
    super(props)
    this.usersStore = this.props.store.usersStore
    this.authStore = this.props.store.authStore
    this.esObjectStore = this.props.store.esObjectStore
    this.state = {
      hideDelDialog: true,
      loading: false,
    }
  }

  componentDidMount() {
    if (this.esObjectStore.mapShops.size === 0) {
        this.esObjectStore.fetchShops().then(() => [...this.esObjectStore.mapShops.values()].forEach(shop => {
          if (!this.usersStore.shopRoles.includes(shop.shop_name)) {
            this.usersStore.shopRoles.push(shop.shop_name)
          }
        }))
    }
  }
  setHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.usersStore.columns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.usersStore.constHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }


  setChecked = (item) => {
    this.usersStore.checked = []
    const roles = this.authStore.usersMap.get(item.id)
    if (roles !== undefined) {
        roles.forEach(role => {
            if (role.value === 1) this.usersStore.checked.push(role.role) 
        }) 
    } else {
        this.usersStore.checked = []
    }
  }

  showEditDialog = item => {
    if (item === undefined) {
      this.usersStore.checked = []
      item = {
        user: ''
      }
    } else {
      this.setChecked(item)
    }
    this.setState({
      hideEditDialog: false,
      editItem: item,
      userOrig: item.user
    })
  }

  handleEdit = () => {
    if (!this.state.editItem.user) {
        return
    }
    if (this.state.editItem.id === undefined && this.authStore.userItems.find(user => user.user === this.state.editItem.user.toLowerCase())) {
        alert('The user already exists !')
        return
    }
    this.setState({ loading: true})
    this.usersStore.editUser(this.state.editItem).then(() => {
      this.setState({
        hideEditDialog: true,
        editItem: undefined,
        loading: false,
      })
    })
  }

  handleCloseEditDialog = () => {
    this.setState({ hideEditDialog: true })
    this.setChecked(this.state.editItem)
    this.state.editItem.user = this.state.userOrig
  }

  showDelDialog = index => {
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.usersStore.removeUser(this.state.delIndex)
    this.setState({
      hideDelDialog: true,
      delIndex: -1
    })
  }

  handleItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={editIcon}
            title={t('common:USER_EDIT')}
            onClick={() => this.showEditDialog(item)}
          />
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:USER_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  render () {
    const { t, store: { authStore: { userItems }, usersStore: { appRoles, commonRoles, shopRoles, unitreeRoles, categoriesRoles, brandPriorityRoles, formatGaRoles } } } = this.props
    const { hideEditDialog, hideDelDialog, editItem, delIndex, loading } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    const containerStyle = {
      root: {
        width: '100%',
        height: '100%'
      }
    }
    const growingStyles = {
        root: {
            display: 'flex',
            height: '50%'
        }
    }
    const textFieldsStyles = {
      fieldGroup: { width: 255, height: 20, float: 'right' }
    }
    return (
      <div className='page-container'>
        <Stack verticall tokens={stackTokens} styles={containerStyle}>
            <div id='usersDetailsList'>
              <DetailsList ariaLabelForGrid='Users'
                items={userItems} 
                setKey='set'
                columns={this.setHeaderColumns()}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.single}
                selection={this.selection}
                enterModalSelectionOnTouch={true}
                selectionPreservedOnEmptyClick={true}
                constrainMode={ConstrainMode.unconstrained}
                onRenderItemColumn={this.handleItemColumn}
            />
            <IconButton
              iconProps={addIcon}
              title={t('common:USER_ADD')}
              onClick={() => this.showEditDialog()}
          />
        </div>
        </Stack>
        <Dialog
          minWidth={400}
          maxWidth={800}
          hidden={hideEditDialog}
          onDismiss={this.handleCloseEditDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: editItem !== undefined && editItem.id !== undefined ? t('common:USER_EDIT') : t('common:USER_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
            <TextField required label={t('common:USER_EMAIL')} id='user' defaultValue={editItem !== undefined ? editItem.user : ''} 
              onChange={(event, newValue) => {
                if (editItem !== undefined) {
                  editItem.user = newValue
                  this.setState({
                    editItem
                  })
                }
              }}
              styles={textFieldsStyles}/>
            <Stack vertical tokens={stackTokens}>
            <Stack horizontal tokens={stackTokens}>
            <Label>Common tools rights </Label>
                {commonRoles.map((item, index) => (
                    <span key={index} style={ {paddingLeft: 37}}>
                        <Checkbox id={item} label={item} checked={ this.usersStore.checked.includes(item) } onChange={this.usersStore.handleCheck} />
                    </span>
                ))}
              </Stack>
              {/* <Stack horizontal tokens={stackTokens}> */}
                <Label>Access application rights </Label>
                {appRoles.map((item, index) => (
                    <span key={index} style={{paddingLeft: 15}}>
                        <Checkbox id={item} label={item} checked={ this.usersStore.checked.includes(item) } onChange={this.usersStore.handleCheck} />
                    </span>
                ))}
              {/* </Stack> */}
              <Stack horizontal tokens={stackTokens}>
                <Label>Unitree Builder {t('common:USER_ROLES')}</Label>
                {unitreeRoles.map((item, index) => (
                  <span key={index} style={ {paddingLeft: 15}}>
                    <Checkbox id={item} label={item} disabled={ !this.usersStore.checked.includes(appRoles[0]) } checked={ this.usersStore.checked.includes(item) } onChange={this.usersStore.handleCheck} />
                  </span>
                ))}
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Label>Categories Builder {t('common:USER_ROLES')}</Label>
                {categoriesRoles.map((item, index) => (
                  <span key={index} style={{paddingRight: 10}}>
                    <Checkbox id={item} label={item} disabled={ !this.usersStore.checked.includes(appRoles[2]) } checked={ this.usersStore.checked.includes(item) } onChange={this.usersStore.handleCheck} />
                  </span>
                ))}
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Label>Brand Priority {t('common:USER_ROLES')}</Label>
                {brandPriorityRoles.map((item, index) => (
                  <span key={index} style={ {paddingLeft: 21}}>
                    <Checkbox id={item} label={item} disabled={ !this.usersStore.checked.includes(appRoles[3]) } checked={ this.usersStore.checked.includes(item) } onChange={this.usersStore.handleCheck} />
                  </span>
                ))}
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Label>Format ga {t('common:USER_ROLES')}</Label>
                {formatGaRoles.map((item, index) => (
                  <span key={index} style={ {paddingLeft: 37}}>
                    <Checkbox id={item} label={item} disabled={ !this.usersStore.checked.includes(appRoles[4]) } checked={ this.usersStore.checked.includes(item) } onChange={this.usersStore.handleCheck} />
                  </span>
                ))}
              </Stack>
              <Label>Shops {t('common:USER_ROLES')}</Label>
              {shopRoles.map((item, index) => (
                <span key={index} style={{paddingLeft: 15}}>
                  <Checkbox id={item} label={item} disabled={ !this.usersStore.checked.includes(appRoles[0]) && !this.usersStore.checked.includes(appRoles[2]) } checked={ this.usersStore.checked.includes(item) } onChange={this.usersStore.handleCheck} />
                </span>
              ))}
            </Stack>  
          </Stack>
          <DialogFooter>
            {loading && <Spinner size={SpinnerSize.large} /> }
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseEditDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        {hideDelDialog === false && <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:USER_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:USER_DELETE_QESTION') + (delIndex !== undefined && userItems[delIndex] !== undefined ? userItems[delIndex].user : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
      </div>             
    )
  }
}

export { Users }
export default withTranslation(['common'], { wait: true })(withMainContainer(Users))