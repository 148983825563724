import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb';
import { TextField, Dropdown, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import withMainContainer from '../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import FormatGaDocs from './FormatGaDocs'
import dateFormat from "dateformat";

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }
const viewIcon = { iconName: 'LookupEntities' }
const exportIcon = { iconName: 'CloudUpload' }

@inject('store')
@observer
class FormatGa extends Component {
  constructor (props) {
    super(props)
    super(props)
    this.esObjectStore = this.props.store.esObjectStore
    this.formatGaStore = this.props.store.formatGaStore
    this.selection = new Selection({
        onSelectionChanged: () => {
        }
      })
    this.state = {
        showEsObjects: true,
        showDocs: false,
        hideDelDialog: true,
        hideEditDialog: true,
        loadingDocs: false, 
        loadingExport: false,
        showConfirmExportDialog: false
    }
  }

  setHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.esObjectStore.columns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.esObjectStore.constHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  componentDidMount () {
    this.formatGaStore.populateESObjects(true)
  }

  populateDocs = (item) => {
    this.setState({loadingDocs: true})
    this.formatGaStore.selectedESObject = item
    const shop =  [...this.esObjectStore.mapShops.values()].find(el => el.id === item.shop_id)
    if (shop !== undefined) {
      this.esObjectStore.initialiseData(shop, this.props.store.appStateStore.get_gui_language())
      Promise.all([this.esObjectStore.fetchCriterias(this.formatGaStore.selectedESObject.env), 
        this.esObjectStore.fetchGenarts(this.formatGaStore.selectedESObject.env)
      ]).then(() => this.formatGaStore.populateDocs(item.id).then(() => {
        this.breadcrumbitems.push({ text: `${item.shop_name}_${item.index_type}_${item.version_name}`, key: 'docs' }) 
        this.setState({
          showEsObjects: false,
          showDocs: true,
          loadingDocs: false
        })
      }))
    }
  }

  runGitHubAction = async(item) => {
    await this.esObjectStore.runGitHubAction(item.shop_name, item.env)
  }

  exportEsObject = (item) => {
    if (this.state.loadingDocs) {
      return
    }
    this.esObjectStore.getIndexAliases(item).then((actions) =>{
      this.setState({ loadingExport: false})
      if (actions.length > 0) {
        this.setState({ showConfirmExportDialog: true, item, actions})
      } else {
        this.setState({ loadingExport: true})
        console.log(`Will export generic articles format ${item.id} into ES`)  
        this.esObjectStore.exportEsObject(item).then(() => 
          {
            this.setState({ loadingExport: false })
            this.runGitHubAction(item)
            alert(`Generic articles format ${item.id} exported!`)
          }
        )
      }
    })
  }

  onBreadcrumbItemClicked = (ev, item) => {
    this.setState({
      showEsObjects: true,
      showDocs: false
    });
    this.formatGaStore.selectedESObject = null
    this.breadcrumbitems.length > 1 && this.breadcrumbitems.pop()
  }

  breadcrumbitems = [
    { text:  this.props.t(`common:FORMATS`), key: 'formats', onClick: this.onBreadcrumbItemClicked }
  ]

  handleEdit = () => {
    // check if a format_ga with same fields exists
    const found = this.formatGaStore.esObjects.filter(item => (item.shop_name === this.state.editItem.shop_name && 
      item.version_name === this.state.editItem.version_name && item.id !== this.state.editItem.id))
    if (found.length > 0) {
      alert(this.props.t('common:FORMAT_ALREADY_EXISTS_MSG'))
      this.formatGaStore.populateESObjects()
    }  else {
      this.esObjectStore.editESObject(this.state.editItem).then(() => 
        this.formatGaStore.populateESObjects().then(() => 
          this.setState({
            hideEditDialog: true,
            editItem: undefined
          })
        )
      )
    }
  }

  showEditDialog = item => {
    if (this.state.loadingDocs) {
      return
    }
    if (item === undefined) {
      item = {
        index_type: 'format_ga',
        shop_name: '',
        version_name: dateFormat(new Date(), "yyyymmdd"), 
      }
    }
    this.setState({
      hideEditDialog: false,
      editItem: item
    })
  }

  handleCloseEditDialog = () => {
    this.setState({ hideEditDialog: true })
    this.formatGaStore.populateESObjects()
  }
  
  showDelDialog = index => {
    if (this.state.loadingDocs) {
      return
    }
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.esObjectStore.removeESObject(this.formatGaStore.esObjects[this.state.delIndex]).then(() => 
      this.formatGaStore.populateESObjects().then(() =>
        this.setState({
          hideDelDialog: true,
          delIndex: -1
        })
      )
    )
  }

  handleItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={viewIcon}
            title={t('common:FORMAT_VIEW')}
            onClick={() => this.populateDocs(item)}
          />
          <IconButton
            iconProps={editIcon}
            title={t('common:FORMAT_EDIT')}
            onClick={() => this.showEditDialog(item)}
          />
          <IconButton
            iconProps={exportIcon}
            title={t('common:FORMAT_EXPORT')}
            onClick={() => this.exportEsObject(item)}
          />
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:FORMAT_DELETE')}
          />
        </Stack>
      )
    } 
    return item[column.fieldName]
  }

  handleCloseConfirmExportDialog = () => {
    this.setState({ showConfirmExportDialog: false })
  }

  handleExport = () => {
    this.setState({
      showConfirmExportDialog: false, 
      loadingExport: true
    })
    console.log(`Will export generic articles format ${this.state.item.id} into ES`)   
    this.esObjectStore.exportEsObject(this.state.item).then(() => 
      this.esObjectStore.setAliases(this.state.item.env, this.state.actions).then(() => {
        this.setState({ loadingExport: false })
        this.runGitHubAction(this.state.item)
        alert(`Generic articles format ${this.state.item.id} exported!`)
      }
    ))
  }

  onFilterChange = (text) => {
    this.formatGaStore.filterText = text
    this.formatGaStore.filteredDocs = text ? this.formatGaStore.formatGaDocs.filter(i => i.gatxt.toLowerCase().indexOf(text.toLowerCase()) > -1) : this.formatGaStore.formatGaDocs
  }

  render () {
    const { t, store: { formatGaStore: { esObjects, filterText },
      esObjectStore: { shops, envs } } } = this.props
    const { hideEditDialog, hideDelDialog, editItem, loadingDocs, loadingExport, showConfirmExportDialog } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    const containerStyle = {
      root: {
        width: '100%',
        height: '100%'
      }
    }
    const growingStyles = {
      root: {
        display: 'flex',
        height: '50%',
        width: 400,
      }
    }
    const textFieldsStyles = {
      fieldGroup: { width: 200, height: 20, float: 'right' }
    }
    return (
      <div className='page-container'>
        <Stack verticall tokens={stackTokens} styles={containerStyle}>
        <Stack horizontal tokens={stackTokens}>
        <Stack.Item styles={growingStyles}>   
          <Breadcrumb style={{width: '400px'}}
            items={this.breadcrumbitems} 
          />
        </Stack.Item>
        <Stack.Item style={{ padding: 20, paddingLeft: 100 }}> 
          { this.state.showDocs && <TextField label={t('common:SEARCH')} value={filterText || ''} onChange={(event, newValue) => this.onFilterChange(newValue)} styles={{fieldGroup: { width: 350, height: 30, float: 'right' }}} />}
          </Stack.Item>
        </Stack>
          {loadingDocs && <Spinner size={SpinnerSize.large} /> }
          {loadingExport && <Spinner size={SpinnerSize.large} /> }
          {this.state.showEsObjects && <div id='formatsDetailsList'>
            <DetailsList ariaLabelForGrid='Formats'
              items={esObjects} 
              setKey='set'
              columns={this.setHeaderColumns()}
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.single}
              selection={this.selection}
              enterModalSelectionOnTouch={true}
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={this.populateDocs}
              constrainMode={ConstrainMode.unconstrained}
              onRenderItemColumn={this.handleItemColumn}
            />
            <IconButton
              iconProps={addIcon}
              title={t('common:FORMAT_ADD')}
              onClick={() => this.showEditDialog()}
          />
            </div>
          }
          {this.state.showDocs && <FormatGaDocs/>}
        </Stack>
        <Dialog
          minWidth={400}
          maxWidth={800}
          hidden={hideEditDialog}
          onDismiss={this.handleCloseEditDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: editItem !== undefined && editItem.id !== undefined ? t('common:FORMAT_EDIT') : t('common:FORMAT_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
          <Dropdown id='env'
                placeholder={t('common:UNITREE_DROPDOWN_ENV_PLACEHOLDER')}
                label={t('common:UNITREE_ENV')}
                defaultSelectedKey={editItem !== undefined ? editItem.env : ''}
                options={envs}
                onChange={(event, { key }) => {
                  if (editItem !== undefined) {
                    editItem.env = key
                    this.setState({
                      editItem
                    })
                  }}
                }
                styles={{dropdown: { width: 200, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
            <Dropdown id='shop_name'
                placeholder={t('common:UNITREE_DROPDOWN_SHOP_PLACEHOLDER')}
                label={t('common:UNITREE_SHOP_NAME')}
                defaultSelectedKey={editItem !== undefined ? editItem.shop_id : ''}
                options={shops}
                onChange={(event, { key, text }) => {
                  if (editItem !== undefined) {
                    editItem.shop_name = text
                    editItem.shop_id = key
                    this.setState({
                      editItem
                    })
                  }}
                }
                styles={{dropdown: { width: 200, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
            <TextField label={t('common:UNITREE_VERSION_NUMBER')} id='version_name' defaultValue={editItem !== undefined ? editItem.version_name : ''} 
              onChange={(event, newValue) => {
                if (editItem !== undefined) {
                  editItem.version_name = newValue
                  this.setState({
                    editItem
                  })
                }
              }}
              styles={textFieldsStyles}/>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseEditDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:FORMAT_DELETE'),
            closeButtonAriaLabel: t('common:FORMAT_CLOSE'),
            subText: t('common:FORMAT_DELETE_QESTION')
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        { showConfirmExportDialog && <Dialog
          hidden={!showConfirmExportDialog}
          onDismiss={this.handleCloseConfirmExportDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:FORMAT_EXPORT'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:FORMAT_EXPORT_QESTION')
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 500 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleExport} text={t('common:BUTTON_YES')} />
            <DefaultButton onClick={this.handleCloseConfirmExportDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
      </div>             
    )
  }
}

export { FormatGa }
export default withTranslation(['common'], { wait: true })(withMainContainer(FormatGa))