import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Selection
  } from '@fluentui/react/lib-commonjs/DetailsList'
import { Label, ScrollablePane, ScrollbarVisibility, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, TextField,
  Checkbox, Dialog, DialogType, DialogFooter, Pivot, PivotItem } from '@fluentui/react'
import withMainContainer from '../withMainContainer'
import { withTranslation } from 'react-i18next'
import { Dropdown } from 'semantic-ui-react'

import GenartFilters from './GenartFilters'
import GenartCriteria from './GenartCriteria'
import GenartCupis from './GenartCupis'

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }
const data = require('../../../../config/data.json')

@inject('store')
@observer
class Genarts extends Component {
  constructor (props) {
    super(props)
    this.categoriesStore = this.props.store.categoriesStore
    this.categoriesTreeStore = this.props.store.categoriesTreeStore
    this.genartsStore = this.props.store.genartsStore
    this.genartCriteriaStore = this.props.store.genartCriteriaStore
    this.esObjectStore = this.props.store.esObjectStore
    this.selection = new Selection();
    this.state = {
      hideDelDialog: true
    }
  }

  setGenartsHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.genartsStore.genartsColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.genartsStore.constGenartsHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  handleGenartsItemColumn = (item, index, column) => {
    const { t } = this.props
    if (column.fieldName === 'buttons') {
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={editIcon}
            title={t('common:GENART_EDIT')}
            onClick={() => this.showEditDialog(item, index)}
          />
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:GENART_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  setFilterItems (item) {
    const filterItems = []
    item.filters.forEach(filter => filterItems.push(
      {...filter, 
        filter_catxt: this.props.store.esObjectStore.mapCriterias.get(this.esObjectStore.defaultLang).get(filter.filter_caid)
      }
    ))
    this.genartsStore.filtersItems = filterItems.slice().sort((f1, f2) => Number(f1.filter_sort) - Number(f2.filter_sort))
  }

  onChangeGenart = (gaid) => {
    const { store: { genartsStore: { genartItem, filtersItems }, dialogCategoryStore: { filters },
      categoriesStore: { mapCupis, genartAm } } }  = this.props
    
    genartItem.am = genartAm.includes(gaid) ? 1 : null// the genart is in am table
    
    if (filters.has(gaid)) { // the genart has associated filters in data.json
      if (!filtersItems.find(filter => filter.filter_caid === filters.get(gaid).filter_caid)) {
        genartItem.filters.push(filters.get(gaid))
        this.setFilterItems(genartItem)
      }
    }
    this.genartsStore.cupisItems = mapCupis.has(gaid) ? mapCupis.get(gaid) : []
  }

  setCriteriaTypesValues () {
    const { t } = this.props
    this.genartCriteriaStore.criteriaTypes = []
    data.criteria_types.forEach(criteria_type => {
      if (!this.categoriesStore.selectedESObject.shop_name.toLowerCase().includes('cz') && (criteria_type === '130750F' || criteria_type === '130751F')) {
        // skip
      } else {
        this.genartCriteriaStore.criteriaTypes.push({
          key: criteria_type,
          value: criteria_type,
          text: criteria_type + "-" + t(`common:CRITERIA_${criteria_type}`)
        })
      }
    })
  }

  showEditDialog = (item, index) => {
    this.setCriteriaTypesValues()
    if (item === undefined) {
      item = {
        filters: [],
        criteria: [],
        cupis: [],
        am: null,
        isEdit: false,
      }
    } else {
      item.isEdit = true
      this.setState({
        editIndex: index,
        origGaid: item.gaid,
        origAm: item.am,
        origFilters: [...item.filters],
        origCriteriaType: item.criteriaType,
      })
    }
    this.setFilterItems(item)
    this.genartsStore.criteriaItems = []
    if (item.criteriaType !== undefined) {
      const criterias = this.categoriesStore.mapCriteriaTexts.get(item.criteriaType)
      criterias !== undefined && criterias.forEach(criteria => {
        this.genartsStore.criteriaItems.push({
          'cvp': criteria,
          'cid': '100'
        })
      })
    }
    this.genartsStore.cupisItems = this.categoriesStore.mapCupis.has(item.gaid) ? this.categoriesStore.mapCupis.get(item.gaid) : []
    this.genartsStore.handleGenartDialog(item)
  }

  handleCloseEditDialog = () => {
    this.genartsStore.handleGenartDialog()
    const { editIndex, origGaid, origAm, origFilters, origCriteriaType } = this.state
    if (editIndex !== undefined) {
      this.genartsStore.genartsItems[editIndex].gaid = origGaid
      this.genartsStore.genartsItems[editIndex].gatxt = origGaid + '-' + this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang).get(origGaid)
      this.genartsStore.genartsItems[editIndex].am = origAm
      this.genartsStore.genartsItems[editIndex].filters = origFilters
      this.genartsStore.genartsItems[editIndex].criteriaType = origCriteriaType
      this.setState({
        editIndex: undefined,
        origGaid: '',
        origAm: null
      })
    }
  }

 

  handleEdit = () => {
    if (!this.genartsStore.genartItem.gaid) {
      alert(this.props.t('common:MSG_SELECT_FILTER_GENART'))
      return
    }
    this.genartsStore.editGenart()
    this.genartsStore.handleGenartDialog()
  }

  showDelDialog = index => {
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.genartsStore.removeGenart(this.state.delIndex)
    this.setState({
      hideDelDialog: true,
      delIndex: undefined
    })
  }

  render () {
    const { t, store: { genartsStore: { genartsItems, showGenartDialog, genartItem },
      esObjectStore : { genarts, mapGenarts } } }  = this.props
    const { hideDelDialog, delIndex } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    const stackStyles = {
      root: {
        width: '280px'
      }
    }
    return (
      <React.Fragment>
        <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} styles={{root: {width: '100%', paddingLeft: 10}}}>
            <Label>{t('common:ARTICLES_GENARTS')}</Label>
            <IconButton
              iconProps={addIcon}
              title={t('common:GENART_ADD')}
              onClick={() => this.showEditDialog()}
            />
            <div style={{ position: "relative", height: 450 }} id='genartListItem'>    
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                <DetailsList id='genartDetailsList'
                  items={genartsItems} 
                  setKey='set'
                  columns={this.setGenartsHeaderColumns()}
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.none}
                  enterModalSelectionOnTouch
                  constrainMode={ConstrainMode.unconstrained}
                  onRenderItemColumn={this.handleGenartsItemColumn}
                />
              </ScrollablePane>   
            </div>
          </Stack>
        { showGenartDialog && <Dialog
          minWidth={700}
          maxWidth={900}
          hidden={!showGenartDialog}
          onDismiss={this.handleCloseEditDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: genartItem !== undefined && genartItem.isEdit === true ? t('common:GENART_EDIT') : t('common:GENART_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
            <Stack horizontal tokens={stackTokens}>
              <Label>{t('common:NODE_FILTER_GAID')}</Label>
              <Dropdown
                placeholder={t('common:NODE_FILTER_GAID')}
                defaultValue={genartItem !== undefined ? genartItem.gaid : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={genarts}
                onChange={(e, { value }) => {
                    genartItem.gaid = value
                    genartItem.gatxt = value + '-' + mapGenarts.get(this.esObjectStore.defaultLang).get(value)
                    this.onChangeGenart(value)
                }}
              />
            </Stack>
            <Checkbox label={t('common:CATEGORY_GENART_AM')} checked={genartItem !== undefined && genartItem.am === 1 ? true : false} onChange={(event, newValue) => genartItem.am = newValue ? 1 : null} />
            <Pivot linkFormat="tabs"  onLinkClick={() => {}}> 
                <PivotItem headerText='Criteria' itemKey='criteria' key='criteria'>
                    <div id='criteriaFilters'><GenartCriteria /></div>
                </PivotItem>
                <PivotItem headerText='Cupis' itemKey='cupis' key='cupis'>
                    <div id='cupis'><GenartCupis /></div>
                </PivotItem>
                <PivotItem headerText='Filters' itemKey='filters' key='filters'>
                    <div id='genartFilters'><GenartFilters /></div>
                </PivotItem>
            </Pivot>  
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseEditDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:FILTER_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:FILTER_DELETE_QESTION') + (genartsItems[delIndex] !== undefined ? genartsItems[delIndex].gatxt : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
      </React.Fragment> 
    )
  }
}

export { Genarts }
export default withTranslation(['common'], { wait: true })(withMainContainer(Genarts))