import { observable } from 'mobx'
import config from '../config/config'
import { use } from 'i18next'

class UsersStore {
  @observable columns = []  
  @observable checked = []
  constHeader = [
    { key: 'user', text : 'USER' },
    { key: 'buttons', text: '' }
  ]

  appRoles = ['Unitree Builder', 'Connect Dashboard Tool', 'Category Builder', 'Brand Priority Builder', 'Format Ga Builder']

  commonRoles = ['users', 'shops']

  unitreeRoles = ['import']

  shopRoles = []

  categoriesRoles = ['import_categories', 'config_am', 'config_cupis' ]

  brandPriorityRoles = ['import_brand_priority']

  formatGaRoles = ['import_format_ga']

  constructor (authStore) {
    this.authStore = authStore 
    this.constHeader.forEach(({key}) => this.columns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: '50%',
      minWidth: 120,
      maxWidth: 200,
    })
    )
  }

  // Add/Remove checked item from list
  handleCheck = (event, newValue) => {
    var updatedList = [...this.checked];
    if (newValue) {
      updatedList = [...this.checked, event.target.id]
    } else {
      updatedList.splice(this.checked.indexOf(event.target.id), 1)
    }
    this.checked  = updatedList;
  }

  /**
   * Remove a shop from the DB
   */
  removeUser = async (key) => {
    // remove from DB 
    const item = this.authStore.userItems[key]
    const result = await fetch(`${config.server_address}/fe/users/delete/${item.id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      this.authStore.usersMap.delete(item.id)
      this.authStore.userItems.splice(key, 1)
      alert(`User ${item.user} successfully deleted !`)
    }
  }

  editUser = async (editItem) => {
    // edit in DB 
    const result = await fetch(`${config.server_address}/fe/users/${editItem.id === undefined ? 'insert' : 'edit'}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        id: editItem.id,
        user: editItem.user.toLowerCase()
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      if (editItem.id === undefined) {
        editItem.id = res.message
        this.authStore.userItems.push(editItem)
      }
      // save userRoles
      // this.authStore.usersMap.set(editItem.id, [])
      for (const userRole of this.commonRoles.concat(this.appRoles).concat(this.shopRoles).concat(this.unitreeRoles).concat(this.categoriesRoles).concat(this.brandPriorityRoles).concat(this.formatGaRoles)) {
        await this.saveRole(editItem.id, userRole, this.checked.includes(userRole))
      }
      alert(`User ${editItem.user} successfully saved!`)
    }
  }

  saveRole = async(userId, roleName, roleValue) => {
    const result = await fetch(`${config.server_address}/fe/users/saveUserRole`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        id_user: userId, 
        role_name: roleName,
        role_value: roleValue
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    if (this.authStore.usersMap.get(userId) === undefined) {
      this.authStore.usersMap.set(userId, [])
    }
    const role = this.authStore.usersMap.get(userId).find(role => role.role === roleName)
    if (role === undefined) {
      this.authStore.usersMap.get(userId).push({role: roleName, value: roleValue ? 1 : 0})
    } else {
      role.value = roleValue ? 1 : 0
    }
  }
}
export default UsersStore
