import { observable } from 'mobx'

class FiltersStore {
    @observable criteriaColumns = []  
    @observable showCriteriaDialog = false
    @observable criteriaItem = undefined
    @observable filters = []

    constCriteriaHeader = [
        { key: 'caid', text: 'CRITERIA_CID' },
        { key: 'catxt', text: 'CRITERIA_CVP' },
        // { key: 'filter_open', text: 'NODE_FILTER_OPEN' },
        { key: 'bar', text : 'NODE_FILTER_BAR' },
        { key: 'default', text: 'NODE_FILTER_DEFAULT' },
        // { key: 'filter_uom', text: 'NODE_FILTER_UOM' },
        { key: 'buttons', text: '' }
    ]

    constructor (authStore, esObjectStore, formatGaStore) {
        this.authStore = authStore 
        this.esObjectStore = esObjectStore
        this.formatGaStore = formatGaStore
        this.constCriteriaHeader.forEach(({key, width, minWidth, maxWidth}) => this.criteriaColumns.push({
            key,
            fieldName: key,
            isResizable: true,
            width: key === 'catxt' ? '40%' : '20%',
            minWidth: 45,
            maxWidth: key === 'catxt' ? 250 : 100,
        }))
    }

    /**
    * Hide / Show dialog
    */
    handleCriteriaDialog = (criteria) => {
        this.showCriteriaDialog = !this.showCriteriaDialog
        this.criteriaItem = criteria
    }  

    editCriteria = () => {
        if (!this.criteriaItem.isEdit) {
            this.filters.push(this.criteriaItem)
        }
        this.formatGaStore.editItem.filters = this.filters
        this.handleCriteriaDialog()
    }

    removeCriteria = (criteriaDelIndex) => {
        this.filters.splice(criteriaDelIndex, 1)
        this.formatGaStore.editItem.filters = this.filters
    }
}

export default FiltersStore
