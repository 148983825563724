
import { action, observable } from 'mobx'
import config from '../../config/config'
import { Selection } from '@fluentui/react/lib-commonjs/DetailsList'

class BrandPriorityStore {
  @observable brandPriorities = []
  @observable brandPriorityDocs = []
  @observable editing = false
  @observable editItem
  @observable showDialog = false
  @observable selectedESObject = null
  
  @observable loading = false

  // brands treee
  @observable items = {}
  @observable expandedItems = []
  @observable selectedItems = []
  @observable focusedItem = undefined
  @observable searchTexts = []
  mapChildren = new Map()
  mapSortChildren = new Map()
  @observable filterText
  brandPriorityDocsWithBrands = []
  // brands pagination
  itemsPerPage = 250
  @observable noPages
  @observable currentPage

  // ga group
  @observable gaGroupColumns = []  
  @observable showGaGroupDialog = false
  @observable gaGroup = []
  @observable showGaGroupInsertDialog = false
  @observable gaGroupItem = undefined

  // ga group sort
  @observable gaGroupSort = []

  constGaGroupHeader = [
    { key: 'text', text : 'ARTICLES_GENARTS' },
    { key: 'buttons', text: '' }
  ]

  @observable selectedTabKey = null

  @observable columns = []  

  constHeader = [
    { key: 'gatxt', text : 'ARTICLES_GENARTS' },
    { key: 'buttons', text: '' }
  ]

  @observable filteredSorts = []

  constructor (authStore, esObjectStore) {
    this.authStore = authStore 
    this.esObjectStore = esObjectStore
    this.constGaGroupHeader.forEach(({key}) => this.gaGroupColumns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: key === 'text' ? '75%' : '25%',
      minWidth: key === 'text' ? 150 : 50,
      maxWidth: key === 'text' ? 300 : 100
    }))
    this.constHeader.forEach(({key}) => this.columns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: key === 'buttons' ? '20%' : '80%',
      minWidth: key === 'buttons' ? 50 : 250,
      maxWidth: key === 'buttons' ? 100 : 450,
    }))
  }
  
  /**
   * Get the brand_priority from DB
   */
  populateESObjects = async (reloadShops = false) => {
    this.brandPriorities =(await this.esObjectStore.populateESObjects('brand_priority', reloadShops)).filter(item => this.authStore.hasUserRight(this.esObjectStore.mapShops.get(item.shop_id).shop_name) === true).map(item => ({...item, env_txt: this.esObjectStore.environments[item.env], shop_name: this.esObjectStore.mapShops.get(item.shop_id).shop_name }))
  }

   /**
   * Get the BrandPriority from DB
   */
   populateDocs = async (esObjectId) => {
    this.brandPriorityDocs = []
    this.brandPriorityDocsWithBrands = []
    this.searchTexts = []
    this.filterText = ''
    this.currentPage = 1
    const result = await fetch(`${config.server_address}/fe/brandPriorities/${esObjectId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    if (data.success) {
      if (this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang) !== undefined) {
        this.brandPriorityDocs = data.message.map(item => {
          const node = {...item, gatxt: item.gaid + '-' + this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang).get(item.gaid)}
          this.searchTexts.push({ key: item.gaid, name: node.gatxt, node: node})
          return node
        })
        this.brandPriorityDocsWithBrands = this.brandPriorityDocs.filter(doc => JSON.parse(doc.brands).length > 0)
        this.populateMapChildren()
        this.buildNodesTree()
        this.noPages = Math.ceil(this.brandPriorityDocsWithBrands.filter(doc => doc.parent_gaid === 0).length/this.itemsPerPage)
        console.log(this.brandPriorityDocsWithBrands.length, this.brandPriorityDocsWithBrands.filter(doc => doc.parent_gaid === 0).length, this.noPages)
        if (this.selectedTabKey === null) {
          this.selectedTabKey = 'brands'
        }
        this.filteredSorts = this.brandPriorityDocs
      }
    }
  }

  /**
   * Hide / Show dialog
   */
  handleShowDialog = (item) => {
    this.showDialog = !this.showDialog
    this.editItem = item
  }

  /**
   * Save Brand Priority in the DB
   */
  saveDoc = async (item) => {
    console.log(item)
    // edit in DB 
   const result = await fetch(`${config.server_address}/fe/brandPriorities/${item.id === undefined ? 'insert' : 'edit'}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        id: item.id,
        es_object_id: this.selectedESObject.id,
        gaid: item.gaid,
        parent_gaid: item.parent_gaid === 0 ? null : item.parent_gaid,
        parent_sort_gaid: item.parent_sort_gaid === 0 ? null : item.parent_sort_gaid,
        sort_type: item.sort_type,
        sorts: item.sorts,
        brands: item.brands,
        updated_by: this.authStore.current_user.DisplayName
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
  }

  saveDocs = async (items) => {
    this.loading = true
    this.handleShowDialog()
    // edit in DB 
    for (const item of items) {
      await this.saveDoc(item) 
    }
    await this.populateDocs(this.selectedESObject.id)
    this.loading = false
  }

  /**
   * Remove a Brand Priority from the DB
   */
  removeDoc = async (item) => {
    if (!item) {
      return
    }
    this.loading = true
    // remove from DB 
    const result = await fetch(`${config.server_address}/fe/brandPriorities/delete/${item.id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      // remove deleted node from arrays
      const index = this.brandPriorityDocs.findIndex(doc => doc.id === item.id)
      index > -1 && this.brandPriorityDocs.splice(index, 1)
      
      const indexInSearchTexts = this.searchTexts.findIndex(doc => doc.key === item.gaid)
      indexInSearchTexts > -1 && this.searchTexts.splice(indexInSearchTexts, 1)

      delete this.items[item.gaid]
      this.loading =  false
      
      return true
    }
  }

  // ga group
  populateGaGroup = (item) => {
    this.gaGroup = []
    this.gaGroupSort = []
    if (!item) {
      return
    }
    let parent
    if (this.selectedTabKey === 'brands') {
      if (!item.parent_gaid) { // is parent group 
        parent = item
      } else {
        parent = this.brandPriorityDocs.find(doc => doc.gaid === item.parent_gaid)
      }
      this.gaGroup.push({id : parent.gaid, text: parent.gatxt, parent: undefined})
      // add children to the group
      this.brandPriorityDocs.filter(doc => doc.parent_gaid === parent.gaid).forEach(cG => {
        const obj = {id : cG.gaid, text: cG.gatxt, parent: parent.gaid }
        this.gaGroup.push(obj)
      }) 
    } else {
      // ga group sort
      if (!item.parent_sort_gaid) { // is parent group 
        parent = item
      } else {
        parent = this.brandPriorityDocs.find(doc => doc.gaid === item.parent_sort_gaid)
      }
      this.gaGroupSort.push({id : parent.gaid, text: parent.gatxt, parent: undefined})
      // add children to the group
      this.brandPriorityDocs.filter(doc => doc.parent_sort_gaid === parent.gaid).forEach(cG => {
        const obj = {id : cG.gaid, text: cG.gatxt, parent: parent.gaid }
        this.gaGroupSort.push(obj)
      }) 
    }
  }

  getGaGroup = () => {
    return this.selectedTabKey === 'brands' ? this.gaGroup.map(g => g.id).join(';') : this.gaGroupSort.map(g => g.id).join(';')
  }

  /**
    * Hide / Show dialog
    */
  handleGaGroupDialog = () => {
    this.showGaGroupDialog = !this.showGaGroupDialog
  }  

  removeGaFromGaGroup= (gaDelIndex) => {
    this.selectedTabKey === 'brands' ? this.gaGroup.splice(gaDelIndex, 1) : this.gaGroupSort.splice(gaDelIndex, 1)
  }

  handleCloseGaGroupInsertDialog = (item) => {
    this.showGaGroupInsertDialog = !this.showGaGroupInsertDialog
    this.gaGroupItem = item
  }

  insertGaGroup = () => {
    if (this.gaGroup.findIndex(g => g.id === this.gaGroupItem.id) < 0) {//if not found in the group
      const parent = this.gaGroup.find(g => g.parent_gaid === undefined)
      if (parent) {
        this.gaGroupItem.parent = parent.id
      }
      this.gaGroup.push(this.gaGroupItem)
    }
  }

  insertGaGroupSort = () => {
    if (this.gaGroupSort.findIndex(g => g.id === this.gaGroupItem.id) < 0) {//if not found in the group
      const parent = this.gaGroupSort.find(g => g.parent_sort_gaid === undefined)
      if (parent) {
        this.gaGroupItem.parent = parent.id
      }
      this.gaGroupSort.push(this.gaGroupItem)
    }
  }

  // populate tree
  populateMapChildren = () => {
    this.mapChildren.clear()
    this.brandPriorityDocsWithBrands.forEach(node => {
      if (!node.parent_gaid) {
        node.parent_gaid = 0
      }
      if (!this.mapChildren.has(node.parent_gaid)) {
        this.mapChildren.set(node.parent_gaid, [])
      }
      this.mapChildren.get(node.parent_gaid).push(node.gaid)
    })
    this.mapSortChildren.clear()
    this.brandPriorityDocs.forEach(node => {
      if (node.parent_sort_gaid) {
        if (!this.mapSortChildren.has(node.parent_sort_gaid)) {
          this.mapSortChildren.set(node.parent_sort_gaid, [])
        }
        this.mapSortChildren.get(node.parent_sort_gaid).push(node.gaid)
      }
    })
  }
  buildRootNodes = () => {
    this.selectedItems = []
    this.expandedItems = []
    this.focusedItem = undefined
    const rootChildren = this.mapChildren.get(0).slice(this.itemsPerPage * (this.currentPage-1), this.itemsPerPage * this.currentPage)
    this.items['root']= {
      index: 'root',
      isFolder: true,
      children: rootChildren,
      data: {id: 0, gaid: 0, parent_gaid: null},
    }
 }

  buildNodesTree = () => {
    this.buildRootNodes()
    for (let index = 0; index < this.brandPriorityDocsWithBrands.length; index++) {
      const node = this.brandPriorityDocsWithBrands[index]
      const id = node.gaid
      const children = this.mapChildren.has(id) ? this.mapChildren.get(id) : []
      this.items[id] = {
        index: id,
        isFolder: children.length > 0,
        children: children,
        data: node,
        canMove: true
      }
    }
  }

  @action
  selectItem = (item) => {
    this.expandParents(item)
    this.selectedItems = []
    this.selectedItems.push(item.index)
    this.focusedItem = item.index
    this.scrollToItem(item)
  }

  expandParents = (item) => {
    const parent = Object.values(this.items).find(it =>
      it.data.gaid === item.data.parent_gaid
    )
    if (parent !== undefined) {
      this.expandedItems = [...this.expandedItems, parent.index] // expand parent
      if(parent.index !== 'root') { // is not root expand upper parent
        this.expandParents(parent)
      }
    }
  }

  scrollToItem = (item) => {
    let section = document.getElementById(`brand_node_${item.index}`)
    if (section) {
      section.scrollIntoView()
      return
    }
    const parent = Object.values(this.items).find(it =>
      it.data.gaid === item.data.parent_gaid
    )
    if (parent !== undefined && parent.index !== 'root') {
      this.scrollToItem(parent)
    }
  }

 selectItemPageOnSearch = (item) => {
    if (item.data.parent_gaid === 0) {
      const index = this.mapChildren.get(0).findIndex(child => child === item.data.gaid) + 1
      this.currentPage = Math.ceil(index / this.itemsPerPage)
      this.buildRootNodes()
    } else {
      const parent = Object.values(this.items).find(it =>
        it.data.gaid === item.data.parent_gaid
      )
      this.selectItemPageOnSearch(parent)
    } 
  }
}

export default BrandPriorityStore
