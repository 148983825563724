import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Selection
  } from '@fluentui/react/lib-commonjs/DetailsList'
import { Label, ScrollablePane, ScrollbarVisibility, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, TextField,
  Dialog, DialogType, DialogFooter } from '@fluentui/react'
import withMainContainer from '../../withMainContainer'
import { withTranslation } from 'react-i18next'
import { Dropdown } from 'semantic-ui-react'
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling'
import DialogNodeFilter from './DialogNodeFilter';

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }

const theme = getTheme()
const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
})

@inject('store')
@observer
class NodeFilters extends Component {
  constructor (props) {
    super(props)
    this.nodeStore = this.props.store.nodeStore
    this.dialogNodeStore = this.props.store.dialogNodeStore
    this.dialogNodeFilterStore = this.props.store.dialogNodeFilterStore
    this.esObjectStore = this.props.store.esObjectStore
    this.unitreeStore = this.props.store.unitreeStore
    this.selection = new Selection();
    this.dragDropEvents = this.getDragDropEvents()
    this.draggedItem = undefined
    this.draggedIndex = -1
    this.state = {
      hideDelDialog: true
    }
  }

  setHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.dialogNodeStore.filtersColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.dialogNodeStore.constFiltersHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  setFiltersItems () {
    this.dialogNodeStore.filtersItems = []
    this.nodeStore.editNode.filters.forEach(filter => {
      this.dialogNodeStore.filtersItems.push({gaid: filter.gaid, gatxt: this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang).get(filter.gaid), filter:filter.filter})
    })
  }

  setFilterHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.dialogNodeFilterStore.filterColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.dialogNodeFilterStore.constFilterHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  setCritFilterItems (item) {
    const filterItems = []
    item.filter.forEach(filter => filterItems.push(
      {...filter, 
        filter_catxt: this.esObjectStore.mapCriterias.get(this.esObjectStore.defaultLang).get(filter.filter_caid)
      }
    ))
    this.dialogNodeStore.critFiltersItems = filterItems.slice().sort((f1, f2) => Number(f1.filter_sort) - Number(f2.filter_sort))
  }

  showEditDialog = (item, index) => {
    if (item === undefined) {
      item = {
        filter: [],
        isEdit: false
      }
    } else {
      item.isEdit = true
      this.setState({
        editIndex: index,
        origGaid: item.gaid,
        origFilter: item.filter
      })
    }
    this.setCritFilterItems(item)
    this.dialogNodeStore.handleFilterDialog(item)
  }

  handleCloseEditDialog = () => {
    this.dialogNodeStore.handleFilterDialog()
    const { editIndex, origGaid, origFilter } = this.state
    if (editIndex !== undefined) {
      this.dialogNodeStore.filtersItems[editIndex].gaid = origGaid
      this.dialogNodeStore.filtersItems[editIndex].gatxt = this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang).get(origGaid)
      this.dialogNodeStore.filtersItems[editIndex].filter = origFilter
      this.setState({
        editIndex: undefined,
        origGaid: ''
      })
    }
  }

  handleFiltersItemColumn = (item, index, column) => {
    const { t } = this.props
    if (column.fieldName === 'filter_str') {
      return (
        item.filter.slice().sort((f1, f2) => Number(f1.filter_sort) - Number(f2.filter_sort)).map(f => (<div key={f.filter_caid}>{this.esObjectStore.mapCriterias.get(this.esObjectStore.defaultLang).get(f.filter_caid)}</div>))
      )
    } else if (column.fieldName === 'buttons') {
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={editIcon}
            title={t('common:FILTER_EDIT')}
            onClick={() => this.showEditDialog(item, index)}
          />
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:FILTER_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  handleEdit = () => {
    if (!this.dialogNodeStore.filterItem.gaid) {
      alert(this.props.t('common:MSG_SELECT_FILTER_GENART'))
      return
    }
    if (this.dialogNodeStore.filterItem.filter.length === 0) {
      alert(this.props.t('common:MSG_SELECT_CRITERIA'))
      return
    }
    this.dialogNodeStore.editFilter()
    this.dialogNodeStore.handleFilterDialog()
  }

  showDelDialog = index => {
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.dialogNodeStore.removeFilter(this.state.delIndex)
    this.setState({
      hideDelDialog: true,
      delIndex: undefined
    })
  }

  handleFilterItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={editIcon}
            title={t('common:FILTER_EDIT')}
            onClick={() => this.showEditFilterDialog(item, index)}
          />
          <IconButton
            onClick={() => this.showDelFilterDialog(index)}
            iconProps={delIcon}
            title={t('common:FILTER_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  showEditFilterDialog = (item, index) => {
    if (item === undefined) {
      const sortFilters = this.dialogNodeStore.filterItem.filter.map(filter => Number(filter.filter_sort))
      item = {
       filter_default: null,
       filter_bar: null,
       filter_open: 0,
       filter_uom: '',
       filter_sort: (sortFilters.length > 0 ? Math.max(...sortFilters) + 1 : 1).toString(),
       isEdit: false
      }
    } else {
      item.isEdit = true
      // save values before editing
      this.dialogNodeFilterStore.origFilter = JSON.stringify(item)
      this.dialogNodeFilterStore.editFilterIndex = index
    }
    this.dialogNodeFilterStore.handleEditFilterDialog(item)
  }
  
  showFilterDialog = item => {
    this.dialogNodeStore.handleFilterDialog()
  }

  showDelFilterDialog = index => {
    this.dialogNodeFilterStore.handleDelFilterDialog(index)
  }

  handleFilterDelete = () => {
    this.dialogNodeFilterStore.removeFilter()
    this.dialogNodeFilterStore.handleDelFilterDialog()
  }

  getDragDropEvents = () => {
    return {
      canDrop: (dropContext, dragContext) => {
        return true
      },
      canDrag: (item) => {
        return true
      },
      onDragEnter: (item, event) => {
        // return string is the css classes that will be added to the entering element.
        return dragEnterClass
      },
      onDragLeave: (item, event) => {
        return
      },
      onDrop: (item, event) => {
        if (this.draggedItem) {
          this.insertBeforeItem(item);
        }
      },
      onDragStart: (item, itemIndex, selectedItems, event) => {
        this.draggedItem = item;
        this.draggedIndex = itemIndex;
      },
      onDragEnd: (item, event) => {
        this.draggedItem = undefined;
        this.draggedIndex = -1;
      },
    };
  }

  insertBeforeItem = async(item) => {
    const draggedItems = this.selection.isIndexSelected(this.draggedIndex)
      ? (this.selection.getSelection()) : [this.draggedItem]

    const insertIndex = this.dialogNodeStore.critFiltersItems.indexOf(item)
    const items = this.dialogNodeStore.critFiltersItems.filter(itm => draggedItems.indexOf(itm) === -1)

    items.splice(insertIndex, 0, ...draggedItems)
    this.dialogNodeStore.critFiltersItems = items

    // update sort field
    const sort = this.dialogNodeStore.critFiltersItems.map(item => Number(item.filter_sort))
    const minsort = sort.length > 0 ? Math.min(...sort) : 1
    for (let index = 0; index < this.dialogNodeStore.critFiltersItems.length; index++) {
      this.dialogNodeStore.critFiltersItems[index].filter_sort = (minsort+index).toString()
    }
    this.dialogNodeStore.filterItem.filter = this.dialogNodeStore.critFiltersItems
  }

  render () {
    const { t, store: { dialogNodeStore: { filtersItems, showFilterDialog, filterItem, critFiltersItems },
      dialogNodeFilterStore: { hideEditFilterDialog, hideDelFilterDialog, delFilterIndex },
      esObjectStore : { genarts, mapGenarts } } }  = this.props
    const { hideDelDialog, delIndex } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    const stackStyles = {
      root: {
        width: '280px'
      }
    }
    return (
      <React.Fragment>
        <Stack verticall tokens={stackTokens}>
          <Stack horizontal tokens={stackTokens} styles={stackStyles}>
            <IconButton
              iconProps={addIcon}
              title={t('common:FILTER_ADD')}
              onClick={() => this.showEditDialog()}
            />
          </Stack>
          <div style={{ position: "relative", height: 450 }} id='criteriaListItem'>    
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
              <DetailsList id='filtersDetailsList'
                items={filtersItems} 
                setKey='set'
                columns={this.setHeaderColumns()}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                enterModalSelectionOnTouch
                constrainMode={ConstrainMode.unconstrained}
                onRenderItemColumn={this.handleFiltersItemColumn}
              />
            </ScrollablePane>   
          </div>
        </Stack>
        { showFilterDialog && <Dialog
          minWidth={700}
          maxWidth={900}
          hidden={!showFilterDialog}
          onDismiss={this.handleCloseEditDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: filterItem !== undefined && filterItem.isEdit === true ? t('common:FILTER_EDIT') : t('common:FILTER_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
            <Stack horizontal tokens={stackTokens}>
              <Label>{t('common:NODE_FILTER_GAID')}</Label>
              <Dropdown
                placeholder={t('common:NODE_FILTER_GAID')}
                defaultValue={filterItem !== undefined ? filterItem.gaid : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={genarts}
                onChange={(e, { value }) => {
                  filterItem.gaid = value
                  filterItem.gatxt = mapGenarts.get(this.esObjectStore.defaultLang).get(value)
                }}
              />
              </Stack>
              <IconButton
                iconProps={addIcon}
                title={t('common:FILTER_ADD')}
                onClick={() => this.showEditFilterDialog()}
              />         
              <div style={{ position: "relative", height: 300 }} id='filterListItem'>    
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                  <DetailsList id='filterDetailsList'
                    items={critFiltersItems} 
                    setKey='set'
                    columns={this.setFilterHeaderColumns()}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    selection={this.selection}
                    enterModalSelectionOnTouch
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderItemColumn={this.handleFilterItemColumn}
                    dragDropEvents={this.dragDropEvents}
                  />
                </ScrollablePane>   
              </div>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseEditDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:FILTER_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:FILTER_DELETE_QESTION') + (filtersItems[delIndex] !== undefined ? filtersItems[delIndex].gatxt : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={hideDelFilterDialog}
          onDismiss={this.showDelFilterDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:FILTER_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:FILTER_DELETE_QESTION') + (critFiltersItems[delFilterIndex] !== undefined ? critFiltersItems[delFilterIndex].filter_catxt : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleFilterDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.showDelFilterDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        {hideEditFilterDialog === false && <DialogNodeFilter />}
      </React.Fragment> 
    )
  }
}

export { NodeFilters }
export default withTranslation(['common'], { wait: true })(withMainContainer(NodeFilters))